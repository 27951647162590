import * as React from "react";
import type { HeadFC, PageProps } from "gatsby";
import BaseLayout from "../layouts/BaseLayout/BaseLayout";
import { Header, PrivacyPolicy } from "../components";
import { useEffect, useState } from "react";

const IndexPage: React.FC<PageProps> = () => {
  const [isLadingPage, setIsLadingPage] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLadingPage(true);
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  if (!isLadingPage) {
    return null;
  }

  return (
    <BaseLayout>
      <Header />
      <PrivacyPolicy />
    </BaseLayout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>Privacy Policy</title>
    <link rel={"canonical"} href={"https://serpnest.com/privacy-policy/"} />
  </>
);
